import { Grid, Typography, Box } from "@mui/material";
import MasterLayout from "./MasterLayout";
import ImageBannerWithText from "../components/ImageBannerWithText";
import { useState, useEffect } from "react";

const PersonElement = (props) => {
  return (
    <Box
      sx={{
        textAlign: "center",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <img
        src={props.imgLink}
        alt={props.imgAltText}
        style={{ height: "210px", borderRadius: "8px", marginBottom: "16px" }}
      />
      <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold" }}>
        {props.name}
      </Typography>
      <Typography variant="h6" gutterBottom>
        {props.title}
      </Typography>
      <Box sx={{ textAlign: "left", padding: "0 16px 16px" }}>
        <Typography variant="body1" gutterBottom>
          {props.description}
        </Typography>
      </Box>
    </Box>
  );
};

const PersonElementHolder = ({ peopleDataArray }) => {
  const [itemsPerRow, setItemsPerRow] = useState(3);

  useEffect(() => {
    const calculateItemsPerRow = () => {
      const width = window.innerWidth;
      if (width < 600) setItemsPerRow(1);
      else if (width < 900) setItemsPerRow(2);
      else if (width < 1200) setItemsPerRow(3);
      else setItemsPerRow(4);
    };

    calculateItemsPerRow();
    window.addEventListener("resize", calculateItemsPerRow);

    return () => {
      window.removeEventListener("resize", calculateItemsPerRow);
    };
  }, []);

  const rows = [];
  for (let i = 0; i < peopleDataArray.length; i += itemsPerRow) {
    let row = peopleDataArray.slice(i, i + itemsPerRow);

    // Handle case where last row has only 1 item by moving 1 item from the previous row to make it 3-2
    if (row.length === 1 && i !== 0 && itemsPerRow > 2) {
      if (rows[rows.length - 1].length > 2) {
        const movedItem = rows[rows.length - 1].pop();
        row.unshift(movedItem);
      }
    }
    rows.push(row);
  }

  return (
    <>
      {rows.map((row, rowIndex) => (
        <Grid
          container
          justifyContent="center"
          alignItems="stretch"
          spacing={2}
          key={rowIndex}
        >
          {row.map((personData, index) => (
            <Grid
              item
              xs={peopleDataArray.length === 1 ? 5 : 12 / itemsPerRow}
              sm={peopleDataArray.length === 1 ? 5 : 12 / itemsPerRow}
              md={peopleDataArray.length === 1 ? 5 : 12 / itemsPerRow}
              key={index}
              sx={{ display: "flex" }}
            >
              <PersonElement
                imgLink={personData.imgLink}
                imgAltText={personData.imgAltText}
                name={personData.name}
                title={personData.title}
                description={personData.description}
              />
            </Grid>
          ))}
        </Grid>
      ))}
    </>
  );
};

const AboutUsLayout = () => {
  const PEOPLE_DATA = [
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/david-schanzlin.jpg",
      imgAltText: "David J. Schanzlin MD",
      name: "David J. Schanzlin MD",
      title: "Chairman & Founder",
      description: (
        <>
          <b>Dr. David Schanzlin, MD</b>, Chairman and Founder, an Ophthalmology
          Professor Emeritus, has an illustrious career. Currently, a senior
          partner at <i>Gordon Schanzlin New Vision Institute</i> in La Jolla,
          California, Dr. Schanzlin boasts over 40 years of experience in
          clinical and research Ophthalmology including over three decades of
          academic ophthalmology. He's recognized worldwide as an esteemed
          educator and a leading researcher in Cornea and Refractive surgery.
          His advisory roles with various start-up companies underscore his
          expertise, while his active involvement in applying AI to clinical
          ophthalmology highlights his understanding of the eye care community's
          unique complexities.
        </>
      ),
    },
  ];

  const CONSULTANT_DATA = [
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/mike-kemper.jpg",
      imgAltText: "Michael Kemper",
      name: "Michael Kemper",
      title: "Lead Programmer",
      description: (
        <>
          <b>Michael Kemper</b> is an experienced software engineer with a
          strong background in web development, cloud computing, and AI
          technologies. Michael excels in <i>JavaScript</i> and <i>Python</i>,
          and is currently focusing on integrating machine learning capabilities
          into web applications.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/blank-profile.png",
      imgAltText: "Alex Handzel",
      name: "Alex Handzel",
      title: "Lead Machine Learning Programmer",
      description: ``,
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/john-kemper.jpg",
      imgAltText: "John Kemper",
      name: "John Kemper",
      title: "Lead Webmaster",
      description: (
        <>
          <b>John Kemper</b> is a Software Engineer specializing in web
          development and full-stack programming. He has extensive experience
          with <i>React</i> and <i>JavaScript</i>, and has contributed to
          numerous high-performance websites and applications.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/victoria-hallberg.jpg",
      imgAltText: "Victoria Hallberg",
      name: "Victoria Hallberg",
      title: "Chief Growth Officer",
      description: (
        <>
          <b>Victoria Hallberg</b> is a seasoned executive with 35 years
          of vision industry experience primarily focused on go-to-market strategy,
           optical retail strategy, sales and marketing leadership. Prior to joining Oculogyx.ai,
            Victoria held leadership positions with <i>DigitalOptometrics</i>, <i>Safilo Group</i>, <i>VSP</i> and <i>Marchon Eyewear</i>.
             A dynamic and highly motived and market-driven leader, throughout her career, Victoria has successfully
              delivered impactful, strategic business growth programs to sales teams, optical retail chains and independent eyecare professionals, positively impacting their business.
        </>
      ),
    },
  ];

  const MEDICAL_ADVISORY_BOARD_DATA = [
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/julio-echegoyen.jpg",
      imgAltText: "Julio Echegoyen, MD, PhD",
      name: "Julio Echegoyen, MD, PhD",
      title: "",
      description: (
        <>
          <b>Dr. Julio Echegoyen</b> is a cataract, cornea, anterior segment, and
          refractive surgeon at <i>Gordon Schanzlin New Vision Institute</i>. He
          completed his medical education and training at <i>UC Irvine</i> and{" "}
          <i>UC San Diego</i>.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/melvani-rakhi.jpg",
      imgAltText: "Rakhi Melvani, MD",
      name: "Rakhi Melvani, MD",
      title: "",
      description: (
        <>
          <b>Dr. Rakhi Melvani</b> is a cataract, cornea, anterior segment, and
          refractive surgeon at <i>Gordon Schanzlin New Vision Institute</i>. She
          completed her medical education at <i>Virginia Commonwealth University</i> and her
          residency at <i>Wills Eye Hospital</i> in Philadelphia.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/alyssa-pack.jpg",
      imgAltText: "Alyssa Pack, OD",
      name: "Alyssa Pack, OD",
      title: "",
      description: (
        <>
          <b>Dr. Alyssa Pack</b> is an owner of Eye Care at the Cove in La Jolla,
          CA. She completed her undergraduate degree in Biological Sciences at{" "}
          <i>University of California Irvine</i> and graduated from the{" "}
          <i>Southern California College of Optometry</i> with Summa Cum Laude
          honors.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/michael-ammar.jpg",
      imgAltText: "Michael Ammar, MD",
      name: "Michael Ammar, MD",
      title: "",
      description: (
        <>
          <b>Dr. Michael Ammar</b> is a vitreoretinal surgeon at{" "}
          <i>Retina Consultants of San Diego</i>. He received his medical degree
          from the <i>University of Southern California</i> and did his fellowship
          at the <i>Wills Eye Hospital</i> in Philadelphia.
        </>
      ),
    },
    {
      imgLink:
        "https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/people/david-geffen.jpeg",
      imgAltText: "David Geffen, OD",
      name: "David Geffen, OD",
      title: "",
      description: (
        <>
          <b>Dr. David Geffen</b> is the senior optometrist at{" "}
          <i>Gordon Schanzlin New Vision Institute</i>. He earned his Doctor of
          Optometry from <i>UC Berkeley</i> and has extensive experience in
          optical correction and complicated contact lens fitting.
        </>
      ),
    },
  ];
  

  return (
    <MasterLayout>
      <ImageBannerWithText
        header="ABOUT US"
        subHeader={`The team at Oculogyx is working to bring artificial intelligence's efficiencies to the daily practices of Ophthalmologists and Optometrists. We have developed several tools to enhance practice efficiency, improve compliance, increase revenue, and apply numerous tools to improve the patient experience.  We have also developed tools to enhance physician academic pursuits through our education and Scholar BOTS.`}
        imgLink="https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/eye-model-3d.jpeg"
        imgAltText="About Us"
      />
      <br />
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        About OculogyX
      </Typography>
      <Typography gutterBottom sx={{ textAlign: "center" }}>
        {`At OculogyX, our mission is to revolutionize Ophthalmic Healthcare through the adoption of Artificial Intelligence (AI) and machine learning tools in everyday practice. We're dedicated to enhancing the field of ophthalmology and providing valuable resources for physicians, staff, and patients.`}
      </Typography>
      <Typography gutterBottom sx={{ textAlign: "center" }}>
        <b>Introducing GenA Eye: </b>
        {`Our groundbreaking AI, GenA Eye, is tailored specifically for ophthalmic professionals. It serves as an invaluable library resource, empowering physicians, staff, and patients with the latest advancements in AI-driven ophthalmic solutions.`}
      </Typography>
      <Typography gutterBottom sx={{ textAlign: "center" }}>
        <b>Advancing Ophthalmic Image Analysis: </b>
        {`We're actively developing Machine Learning applications to automate and streamline ophthalmic image analyses. This not only enhances the efficiency of physician workflows but also improves diagnostic accuracy.`}
      </Typography>
      <Typography gutterBottom sx={{ textAlign: "center" }}>
        <b>Elevating Patient Care and Practice Management: </b>
        {`In the realm of practice management, we're simplifying patient intake procedures and facilitating improved patient-doctor interactions using AI tools. Our focus is on enhancing communication and education within the ophthalmic healthcare ecosystem.`}
      </Typography>
      <br />
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        Meet our Team
      </Typography>
      <br />
      <PersonElementHolder peopleDataArray={PEOPLE_DATA} />
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        <br />
        Consultants
      </Typography>
      <br />
      <PersonElementHolder peopleDataArray={CONSULTANT_DATA} />

      <br />
      <Typography
        variant="h4"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        Medical Advisory Board
      </Typography>
      <br />
      <PersonElementHolder peopleDataArray={MEDICAL_ADVISORY_BOARD_DATA} />
      <br />
      <br />
      <Typography
        variant="h6"
        gutterBottom
        sx={{ fontWeight: "bold", textAlign: "center" }}
      >
        Thank you for your interest in OculogyX. We are committed to pushing the
        boundaries of ophthalmic healthcare through innovative AI solutions.
      </Typography>
      <br />
      <br />
    </MasterLayout>
  );
};

export default AboutUsLayout;
