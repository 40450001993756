import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Fade,
} from '@mui/material';
import { getSessionJwt } from '../util/utilities';
import { ENDPOINTS } from '../util/constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', md: 500 },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ReplyModalLayout = ({ open, onClose, onSuccess, postId, parentId, reply, userId }) => {
  const isEditMode = Boolean(reply);
  const [content, setContent] = useState(reply ? reply.replyContent : '');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Update state when the reply prop changes (for edit mode)
  useEffect(() => {
    if (reply) {
      setContent(reply.replyContent);
    } else {
      setContent('');
    }
  }, [reply]);

  const handleSubmit = async () => {
    if (!content.trim()) {
      setError('Content is required.');
      return;
    }
    setSubmitting(true);
    setError('');

    try {
      const jwtToken = await getSessionJwt();

      let response;
      if (isEditMode) {
        // Editing an existing reply
        response = await fetch(`${ENDPOINTS.EC2_ENDPOINT}/forum/posts/${postId}/replies/${reply.replyId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ content, userId }),
        });
      } else {
        // Creating a new reply
        response = await fetch(`${ENDPOINTS.EC2_ENDPOINT}/forum/posts/${postId}/replies`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ content, parentId, userId }),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(isEditMode ? 'Reply Updated:' : 'Reply Created:', data);

      // Call the onSuccess callback with the new or updated reply
      onSuccess(isEditMode ? data.results.reply : data.results.reply);

      // Reset form fields
      setContent('');
      onClose(); // Close the modal upon success
    } catch (error) {
      console.error(isEditMode ? 'Error updating reply:' : 'Error creating reply:', error);
      setError(error.message || `Failed to ${isEditMode ? 'update' : 'create'} reply.`);
    }

    setSubmitting(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500, // Duration of the backdrop transition
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" gutterBottom>
            {isEditMode ? 'Edit Reply' : 'Add Reply'}
          </Typography>
          {error && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            label="Content"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onClose} sx={{ marginRight: 1 }} disabled={submitting}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit} disabled={submitting}>
              {submitting ? <CircularProgress size={24} /> : isEditMode ? 'Update' : 'Reply'}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ReplyModalLayout;
