import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  Box,
  Container,
  Grid,
  useMediaQuery,
} from "@mui/material";
import { Link } from "react-router-dom";
import DropDownButton from "../components/DropDownButton";

export const ophthalmologyTools = [
  { text: "RETINAL IMAGES", link: "/retinal-images" },
  { text: "OPTOS IMAGES", link: "/optos-images" },
  { text: "RETINAL OCT", link: "/retinal-oct" },
  { text: "CORNEAL TOPOGRAPHY", link: "/corneal-topography" },
  {
    text: "OPD-SCAN III WAVEFRONT ABERROMETER",
    link: "/opd-scan-iii-wavefront-aberrometer",
  },
  { text: "PENTACAM", link: "/pentacam" },
  { text: "HD ANALYZER", link: "/hd-analyzer" },
  { text: "VISUAL FIELD TESTING", link: "/visual-field-testing" },
  { text: "CORNEAL IMAGES", link: "/corneal-images" },
  { text: "MEIBOMIAN GLAND IMAGES", link: "/meibomian-gland-images" },
  { text: "LIPIVIEW", link: "/lipiview" },
  {
    text: "REFRACTIVE SURGERY ASSESSMENT",
    link: "/refractive-surgery-assessment",
  },
  { text: "IOL SELECTION ASSESSMENT", link: "/iol-selection-assessment" },
];

function populateHeaderCategories(menuItems, isMobile, open, setOpen) {
  const items = [];

  for (const [index, value] of menuItems.entries()) {
    items.push(
      <Grid
        item
        xs={value?.subItems?.length > 0 ? 2 : value?.text?.length > 10 ? 2 : 1}
        sx={{ textAlign: "center" }}
      >
        <DropDownButton key={index} dropDownItem={value} isMobile={isMobile} />
      </Grid>
    );
  }

  return (
    <>
      {isMobile && (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
              <Box
                sx={{
                  backgroundColor: "white",
                  padding: "5px",
                  borderRadius: "5px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <img
                  src="https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/site-logo.png"
                  alt="OculogyX Logo"
                  style={{ height: 40 }}
                />
              </Box>
            </Link>
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Button
              disableRipple
              sx={{
                color: "#FFFFFF",
                fontSize: isMobile ? 10 : 14,
                fontFamily: "system-ui",
                fontWeight: 400,
                alignContent: "center",
              }}
              onClick={() => setOpen((o) => !o)}
            >
              {`${!open ? "Open" : "Hide"} Menu`}
            </Button>
          </Box>
        </>
      )}
      {((open && isMobile) || !isMobile) && (
        <Grid
          container
          columns={{ xs: 1, sm: 8, md: 12 }}
          sx={{ alignItems: "center" }}
        >
          <Grid item xs={1} />
          {items}
          <Grid item xs={1} />
        </Grid>
      )}
    </>
  );
}

export default function SiteHeader() {
  const isMobile = useMediaQuery("(max-width:600px)");
  const [open, setOpen] = useState(false);

  const menuItems = [
    { text: "Home", link: "/" },
    { text: "Forum", link: "/forum" },
    { text: "About Us", link: "/about-us" },
    { text: "GenA Eye", link: "/genaeye" },
    // { text: "EYEGPT", link: "/eyegpt" },
    {
      text: "Image Analysis",
      link: "/image-analysis",
    },
    {
      text: "Practice Tools",
      subItems: [
        {
          text: "Ophthalmic Scribe",
          link: "/ophthalmic-scribe",
        },
        {
          text: "Ophthalmic Triage",
          link: "/ophthalmic-triage",
        },
        {
          text: "Refractive Surgery Advisor",
          link: "/refractive-surgery",
        },
        {
          text: "Wavefront Optimized Treatment Plan",
          link: "/wavefront-optimized-treatment-plan",
        },
        {
          text: "Practice Information Agent",
          link: "/practice-information-agent",
        },
        {
          text: "Ophthalmic Pre-Visit Interview",
          link: "/previsit-interview",
        },
      ],
    },
    {
      text: "OPHTHALMIC SCHOLAR",
      link: "/ophthalmic-scholar",
      subItems: [
        {
          text: "Ophthalmic Study Guide",
          link: "/ophthalmic-study-guide",
        },
        {
          text: "OculogyX Research Assistant",
          link: "/research-assistant",
        },
        {
          text: "Ophthalmic Patient History",
          link: "/ophthalmic-patient-history",
        },
      ],
    },
  ];

  return (
    <>
      {!isMobile ? (
        <AppBar position="static" sx={{ bgcolor: "#103444" }}>
          <Toolbar>
            <Box sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
                <Box
                  sx={{
                    backgroundColor: "white",
                    padding: "5px",
                    borderRadius: "5px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src="https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/site-logo.png"
                    alt="OculogyX Logo"
                    style={{ height: 40 }}
                  />
                </Box>
              </Link>
            </Box>
            {populateHeaderCategories(menuItems, isMobile, open, setOpen)}
          </Toolbar>
        </AppBar>
      ) : (
        <Box sx={{ bgcolor: "#103444", py: !isMobile && "25px" }}>
          <Container maxWidth="mx">
            {populateHeaderCategories(menuItems, isMobile, open, setOpen)}
          </Container>
        </Box>
      )}
    </>
  );
}
