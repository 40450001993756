import MasterLayout from "./MasterLayout";
import ImageBannerWithText from "../components/ImageBannerWithText";

const HomepageLayout = () => {
  return (
    <MasterLayout>
      <ImageBannerWithText
        header="MAKING OPHTHALMIC PRACTICE MORE EFFICIENT THROUGH ARTIFICIAL INTELLIGENCE"
        subHeader={
          <>
            Oculogyx is committed to enhancing your practice with AI
            applications that will:
            <ul>
              <li>Enhance revenue through automated image description,</li>
              <li>
                Streamline practice operations through automation (patient
                intake, charting, dictation, coding, referral letters, and
                interoffice communications),
              </li>
              <li>
                Provide a Library of Ophthalmic Information to update you
                through our GenA Eye, provide insights on clinical
                presentations, and make suggestions for clinical management.
              </li>
            </ul>
          </>
        }
        imgLink="https://oculogyxpublicdatabucket.s3.amazonaws.com/photos/closeup-eye.jpeg"
        imgAltText="Eye"
      />
    </MasterLayout>
  );
};

export default HomepageLayout;
