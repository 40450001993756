import React from "react";
import { record } from "aws-amplify/analytics";
import { Button, Stack } from "@mui/material";
import MasterWithoutFooterLayout from "./MasterWithoutFooterLayout";

export default function EventTestLayout() {
  return (
    <MasterWithoutFooterLayout>
      <Stack>
        <Button
          onClick={() => {
            record({
              name: "test-event" + Date.now(),
            });
          }}
        >
          LAUNCH EVENT
        </Button>
      </Stack>
    </MasterWithoutFooterLayout>
  );
}
