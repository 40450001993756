import SiteHeader from "./SiteHeader";

const MasterWithoutFooterLayout = (props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        margin: 0,
      }}
    >
      <SiteHeader />
      <main
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {props.children ? props.children : <h1>Missing props!</h1>}
      </main>
    </div>
  );
};

export default MasterWithoutFooterLayout;
