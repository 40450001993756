// PostModalLayout.js

import React, { useState, useEffect } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Fade,
} from '@mui/material';
import { getSessionJwt } from '../util/utilities';
import { ENDPOINTS } from '../util/constants';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: { xs: '90%', md: 500 },
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const PostModalLayout = ({ open, onClose, onSuccess, post, userId }) => {
  const isEditMode = Boolean(post);
  const [title, setTitle] = useState(post ? post.title : '');
  const [content, setContent] = useState(post ? post.content : '');
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');

  // Update state when the post prop changes (for edit mode)
  useEffect(() => {
    if (post) {
      setTitle(post.title);
      setContent(post.content);
    } else {
      setTitle('');
      setContent('');
    }
  }, [post]);

  const handleSubmit = async () => {
    if (!title.trim() || !content.trim()) {
      setError('Title and Content are required.');
      return;
    }
    setSubmitting(true);
    setError('');

    try {
      const jwtToken = await getSessionJwt();

      let response;
      if (isEditMode) {
        // Editing an existing post
        response = await fetch(`${ENDPOINTS.EC2_ENDPOINT}/forum/posts/${post.postId}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ title, content, userId }), // Passing userId to backend
        });
      } else {
        // Creating a new post
        response = await fetch(`${ENDPOINTS.EC2_ENDPOINT}/forum/posts`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${jwtToken}`,
          },
          body: JSON.stringify({ title, content, userId }), // Passing userId to backend
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error.message || `HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log(isEditMode ? 'Post Updated:' : 'Post Created:', data);

      // Call the onSuccess callback with the new or updated post
      onSuccess(data.results.post);

      // Reset form fields
      setTitle('');
      setContent('');
      onClose(); // Close the modal upon success
    } catch (error) {
      console.error(isEditMode ? 'Error updating post:' : 'Error creating post:', error);
      setError(error.message || `Failed to ${isEditMode ? 'update' : 'create'} post.`);
    }

    setSubmitting(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500, // Duration of the backdrop transition
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography variant="h6" component="h2" gutterBottom>
            {isEditMode ? 'Edit Post' : 'Create New Post'}
          </Typography>
          {error && (
            <Alert severity="error" sx={{ marginBottom: 2 }}>
              {error}
            </Alert>
          )}
          <TextField
            label="Title"
            fullWidth
            variant="outlined"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <TextField
            label="Content"
            fullWidth
            variant="outlined"
            multiline
            rows={6}
            value={content}
            onChange={(e) => setContent(e.target.value)}
            sx={{ marginBottom: 2 }}
          />
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onClose} sx={{ marginRight: 1 }} disabled={submitting}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit} disabled={submitting}>
              {submitting ? <CircularProgress size={24} /> : isEditMode ? 'Update' : 'Post'}
            </Button>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default PostModalLayout;
